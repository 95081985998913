import React from 'react';
import {Alert} from '@mui/material';
import {get} from 'lodash';

import {db, auth, functions, storage} from '../../firebase';

import {joinWithAnd} from '../../utils';

export default function() {
    const emulators = {
        auth: /localhost|127/.test(get(auth, 'emulatorConfig.host')),
        functions: !!get(functions, 'emulatorOrigin'),
        firestore: /localhost|127/.test(get(db, '_settings.host')),
        storage: /localhost|127/.test(get(storage, '_host'))
    };

    const usingEmulator = Object.values(emulators).some(v => v);
    if (!usingEmulator) {
        return;
    }

    const enabledEmulators = Object.entries(emulators).map(([key, value]) => {
        if (!value) {
            return null;
        }

        return (
            <strong key={`alert-${key}`}>{key}</strong>
        );
    }).filter(Boolean);

    return (
        <Alert sx={{m: 1, mt: 0, fontSize: 12, py: 0, alignItems: 'center'}} severity="warning">{joinWithAnd(enabledEmulators)} emulator{enabledEmulators.length > 1 && 's'} enabled</Alert>
    );
};