import React, {useState, useContext} from 'react';
import {Divider, Typography, Stack, FormControlLabel, Checkbox, useTheme, useMediaQuery} from '@mui/material';
import {groupBy} from 'lodash';
import moment from 'moment';

import {UserContext} from '../../../contexts/User';

import {FirefighterRanks, TrainingDisciplines} from '../../../data/utils';

import SearchableDataGrid from '../../../components/SearchableDataGrid';

function countUniquePractices(array) {
    const uniqueDates = new Set();
  
    array.forEach(item => {
        const {date} = item;
        if (date && moment.isMoment(date)) {
            const uniqueDay = date.format('YYYY-MM-DD');
            uniqueDates.add(uniqueDay);
        }
    });
  
    return uniqueDates.size;
}

export default function({loading, training: allTraining, activeFilter, onFilter}) {
    const [onlyPractices, setOnlyPractices] = useState(true);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const {users: allUsers} = useContext(UserContext);
    const users = allUsers.filter(user => {
        const {role} = user;

        return FirefighterRanks.includes(role);
    })

    const training = allTraining.filter(training => {
        const {type} = training;

        if (onlyPractices) {
            return type === 'PRACTICE';
        }

        return ['PRACTICE', 'EXTERNAL_COURSE', 'INTERNAL_COURSE'].includes(type);
    });

    const trainingCount = countUniquePractices(training);
    const disciplineTraining = groupBy(training, 'discipline');
    const disciplineTrainingCount = Object.keys(disciplineTraining).reduce((result, key) => {
        result[key] = countUniquePractices(disciplineTraining[key]);
        return result;
    }, {});
    
    const data = users.reduce((result, user) => {
        const {uid, deactivated} = user;
        if (deactivated) {
            return result;
        }

        const userTraining = training.filter(training => (training.members || []).map(t => t.uid).includes(uid));

        const data = {
            ...user,
            training: userTraining || [],
            hours: userTraining.reduce((total, training) => {
                const {hours} = training;
                return total + parseFloat(hours);
            }, 0) || 0
        };

        const percent = (userTraining.length / trainingCount) * 100;
        data.percent = percent;

        for (const key in TrainingDisciplines) {
            const userDisciplineTraining = (disciplineTraining[key] || []).filter(training => (training.members || []).map(t => t.uid).includes(uid));
            const total = Math.min(userDisciplineTraining.length, disciplineTrainingCount[key] || []);
            const hours = userDisciplineTraining.reduce((total, training) => {
                const {hours} = training;
                return total + parseFloat(hours);
            }, 0) || 0;

            const totalHours = userDisciplineTraining.reduce((total, training) => {
                const {hours, members = []} = training;
                return total + (parseFloat(hours) * members.length);
            }, 0) || 0;

            const percent = Math.min((userDisciplineTraining.length / (disciplineTrainingCount[key] || [])) * 100, 100);

            data.disciplines = data.disciplines || {};
            data.disciplines[key] = {
                total,
                hours,
                totalHours,
                percent
            };
        }

        result.push(data);

        return result;
    }, []);

    const handleCellClick = ({field, row}) => {
        if (!onFilter) {
            return;
        }

        const {uid, fullName} = row;

        if (['fullName', 'hours', 'attendance'].includes(field)) {
            return onFilter('fullName', fullName, {uid, field: 'fullName'}, allTraining.map(t => {
                const {members} = t;
                const absent = !members.find(m => m.uid === uid);

                return {
                    ...t,
                    absent
                };
            }));
        } else if (/Total$/.test(field)) {
            const key = field.replace('Total', '');
            
            return onFilter('fullName', `${fullName} (${TrainingDisciplines[key]})`, {uid, field}, (disciplineTraining[key] || []).map(t => {
                const {members} = t;
                const absent = !members.find(m => m.uid === uid);

                return {
                    ...t,
                    absent
                };
            }));
        }
    };

    return (
        <>
            <Stack direction="row" spacing={2} sx={{mb: 1, alignItems: 'center'}}>
                <Typography variant="h6" sx={{flex: 1}}>Total: {trainingCount}</Typography>

                <FormControlLabel
                    labelPlacement="start"
                    control={
                        <Checkbox
                            onChange={e => setOnlyPractices(e.target.checked)}
                            checked={onlyPractices}
                        />
                    }
                    label="Only Include Practices?"
                />
            </Stack>

            <SearchableDataGrid
                initialState={{
                    density: 'compact',
                    pinnedColumns: {
                        left: ['fullName']
                    }
                }}
                getCellClassName={params => {
                    const {field, row} = params;
                    let percent = null;

                    if (['attendance', 'practicePercentage'].includes(field)) {
                        percent = row.percent;
                    }

                    for (const key in TrainingDisciplines) {
                        if ([`${key}Total`, `${key}Percent`].includes(field)) {
                            percent = row.disciplines[key].percent;
                        }
                    }

                    if (percent !== null) {
                        return `percent-cell percent-cell-${Math.round(percent / 5) * 5}`;
                    }

                    return null;
                }}
                rowSelection={false}
                cellSelection={false}
                onCellClick={handleCellClick}
                loading={loading}
                autoHeight
                rows={data}
                hideFooter
                disableColumnSelector
                sx={{
                    mt: 1,
                    mb: 2
                }}
                columns={[
                    {
                        field: 'fullName',
                        headerName: 'Name',
                        minWidth: isSmall ? 90 : 150,
                        renderCell: params => {
                            const {row} = params;
                            const {fullName, shortName} = row;
                            const text = isSmall ? shortName : fullName;
                            const isFiltered = activeFilter && activeFilter.uid === row.uid;
                            const Component = isFiltered ? 'strong' : 'span';

                            return (
                                <Component>
                                    {text}
                                </Component>
                            );
                        }
                    },
                    {
                        field: 'hours',
                        headerName: 'Hours',
                        width: 90,
                        type: 'number',
                        headerAlign: 'center',
                        align: 'center',
                        valueFormatter: value => (value || 0).toFixed(0),
                        renderCell: params => {
                            const {value, row} = params;
                            const isFiltered = activeFilter && activeFilter.field === 'fullName' && activeFilter.uid === row.uid;
                            const Component = isFiltered ? 'strong' : 'span';

                            return (
                                <Component>
                                    {value}
                                </Component>
                            );
                        }
                    },
                    {
                        field: 'attendance',
                        headerName: 'Total',
                        width: 130,
                        headerAlign: 'center',
                        align: 'center',
                        valueGetter: (value, row) => {
                            const {training = []} = row;
                            return training.length;
                        },
                        renderCell: params => {
                            const {value, row} = params;
                            const isFiltered = activeFilter && activeFilter.field === 'fullName' && activeFilter.uid === row.uid;

                            return (
                                <Stack direction="row" spacing={1} sx={{mt: 1}}>
                                    <Typography sx={{flex: 1.4, fontWeight: isFiltered ? 'bold' : '400', textAlign: 'right'}}>{value} / {trainingCount}</Typography>
                                    <Divider orientation="vertical" flexItem />
                                    <Typography sx={{flex: 1, fontWeight: isFiltered ? 'bold' : '400', textAlign: 'left'}}>{row.percent.toFixed(0)}%</Typography>
                                </Stack>
                            )
                        }
                    },
                    ...Object.keys(TrainingDisciplines).reduce((result, key) => {
                        result.push({
                            field: `${key}Total`,
                            headerName: TrainingDisciplines[key],
                            width: 140,
                            headerAlign: 'center',
                            align: 'center',
                            valueGetter: (value, row) => {
                                const {disciplines = {}} = row;
                                const {total} = disciplines[key] || {};
                                return total;
                            },
                            renderCell: params => {
                                const {row} = params;
                                const isFiltered = activeFilter && activeFilter.field === `${key}Total` && activeFilter.uid === row.uid;

                                return (
                                    <Stack direction="row" spacing={1} sx={{mt: 1}}>
                                        <Typography sx={{flex: 1.4, fontWeight: isFiltered ? 'bold' : '400'}}>{row.disciplines[key].total} / {disciplineTrainingCount[key]}</Typography>
                                        <Divider orientation="vertical" flexItem />
                                        <Typography sx={{flex: 1, fontWeight: isFiltered ? 'bold' : '400'}}>{row.disciplines[key].percent.toFixed(0)}%</Typography>
                                    </Stack>
                                )
                            }
                        });

                        return result;
                    }, [])
                ]}
            />
        </>
    );
};