import React from 'react';
import {Controller} from 'react-hook-form';
import {Autocomplete, TextField} from '@mui/material';

export default function ControlledAutocompleteField(props) {
    const {name, label, rules, options, multiple = true, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <Autocomplete
                        value={value || (multiple ? [] : '')}
                        options={options}
                        onChange={(e, newValue) => {
                            onChange(newValue);
                        }}
                        getOptionLabel={option => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        renderInput={params => (
                            <TextField
                                name={name}
                                {...params}
                                label={label}
                            />
                        )}
                        multiple={multiple}
                        {...rest}
                        {...restField}
                    />
                );
            }}
        />
    );
};
