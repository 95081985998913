import React from 'react';
import {alpha} from '@mui/material/styles';
import {svgIconClasses} from '@mui/material/SvgIcon';
import {toggleButtonGroupClasses} from '@mui/material/ToggleButtonGroup';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import {orange, red, green, gray, brand} from '../themePrimitives';

export const inputsCustomizations = {
    MuiButtonBase: {
        defaultProps: {
            disableTouchRipple: true,
            disableRipple: true
        },
        styleOverrides: {
            root: ({theme}) => ({
                boxSizing: 'border-box',
                transition: 'all 100ms ease-in',
                '&:focus-visible': {
                    outline: `3px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                    outlineOffset: '2px'
                }
            })
        }
    },
    MuiButton: {
        styleOverrides: {
            root: ({theme}) => ({
                boxShadow: 'none',
                borderRadius: (theme.vars || theme).shape.borderRadius,
                textTransform: 'none',
                '&.Mui-disabled': {
                    borderColor: 'transparent'
                },
                variants: [
                    {
                        props: {
                            size: 'small'
                        },
                        style: {
                            // height: '2.25rem',
                            // padding: '8px 12px'
                        }
                    },
                    {
                        props: {
                            size: 'medium'
                        },
                        style: {
                            minHeight: '2.5rem', // 40px
                            padding: '8px 12px'
                        }
                    },
                    {
                        props: {
                            size: 'large'
                        },
                        style: {
                            padding: '8px 12px'
                        }
                    },
                    {
                        props: {
                            color: 'primary',
                            variant: 'contained'
                        },
                        style: {
                            color: 'white',
                            backgroundColor: gray[900],
                            // backgroundImage: `linear-gradient(to bottom, ${gray[700]}, ${gray[800]})`,
                            // boxShadow: `inset 0 1px 0 ${gray[600]}, inset 0 -1px 0 1px hsl(220, 0%, 0%)`,
                            border: `1px solid ${gray[700]}`,
                            '&:hover': {
                                backgroundImage: 'none',
                                backgroundColor: gray[700],
                                boxShadow: 'none'
                            },
                            '&:active': {
                                backgroundColor: gray[800]
                            }
                        }
                    },
                    {
                        props: {
                            color: 'success',
                            variant: 'contained'
                        },
                        style: {
                            color: 'white',
                            backgroundColor: green[400],
                            // backgroundImage: `linear-gradient(to bottom, ${alpha(brand[400], 0.8)}, ${brand[500]})`,
                            // boxShadow: `inset 0 2px 0 ${alpha(brand[200], 0.2)}, inset 0 -2px 0 ${alpha(brand[700], 0.4)}`,
                            border: `1px solid ${green[500]}`,
                            '&:hover': {
                                backgroundColor: green[600],
                                boxShadow: 'none'
                            },
                            '&:active': {
                                backgroundColor: green[700],
                                backgroundImage: 'none'
                            }
                        }
                    },
                    {
                        props: {
                            color: 'secondary',
                            variant: 'contained'
                        },
                        style: {
                            boxShadow: 'none',
                            borderRadius: 100,
                            textTransform: 'none',
                            fontWeight: theme.typography.fontWeightMedium,
                            letterSpacing: 0,
                            color: (theme.vars || theme).palette.text.primary,
                            // border: '1px solid ',
                            // borderColor: gray[200],
                            backgroundColor: alpha(gray[50], 0.7),
                            '&:hover': {
                                backgroundColor: gray[100],
                                borderColor: gray[300],
                                boxShadow: 'none'
                            },
                            '&:active': {
                                backgroundColor: gray[200],
                                boxShadow: 'none'
                            }
                        }
                    },
                    {
                        props: {
                            color: 'error',
                            variant: 'contained'
                        },
                        style: {
                            color: 'white',
                            backgroundColor: red[400],
                            border: `1px solid ${red[500]}`,
                            '&:hover': {
                                backgroundColor: red[600],
                                boxShadow: 'none'
                            },
                            '&:active': {
                                backgroundColor: red[600],
                                backgroundImage: 'none'
                            }
                        }
                    },
                    {
                        props: {
                            color: 'warning',
                            variant: 'contained'
                        },
                        style: {
                            backgroundColor: orange[400],
                            border: `1px solid ${orange[500]}`,
                            '&:hover': {
                                backgroundColor: orange[600],
                                boxShadow: 'none'
                            },
                            '&:active': {
                                backgroundColor: orange[600],
                                backgroundImage: 'none'
                            }
                        }
                    },
                    {
                        props: {
                            variant: 'outlined'
                        },
                        style: {
                            padding: '8px 12px',
                            color: (theme.vars || theme).palette.text.primary,
                            border: '1px solid',
                            borderColor: gray[200],
                            backgroundColor: alpha(gray[50], 0.3),
                            '&:hover': {
                                backgroundColor: gray[100],
                                borderColor: gray[300]
                            },
                            '&:active': {
                                backgroundColor: gray[200]
                            }
                        }
                    },
                    {
                        props: {
                            color: 'secondary',
                            variant: 'outlined'
                        },
                        style: {
                            color: brand[700],
                            border: '1px solid',
                            borderColor: brand[200],
                            backgroundColor: brand[50],
                            '&:hover': {
                                backgroundColor: brand[100],
                                borderColor: brand[400]
                            },
                            '&:active': {
                                backgroundColor: alpha(brand[200], 0.7)
                            }
                        }
                    },
                    {
                        props: {
                            variant: 'text'
                        },
                        style: {
                            color: gray[600],
                            '&:hover': {
                                backgroundColor: gray[100]
                            },
                            '&:active': {
                                backgroundColor: gray[200]
                            }
                        }
                    },
                    {
                        props: {
                            color: 'secondary',
                            variant: 'text'
                        },
                        style: {
                            color: brand[700],
                            '&:hover': {
                                backgroundColor: alpha(brand[100], 0.5)
                            },
                            '&:active': {
                                backgroundColor: alpha(brand[200], 0.7)
                            }
                        }
                    }
                ]
            })
        }
    },
    MuiIconButton: {
        styleOverrides: {
            root: ({theme}) => ({
                boxShadow: 'none',
                borderRadius: 100,
                textTransform: 'none',
                fontWeight: theme.typography.fontWeightMedium,
                letterSpacing: 0,
                color: (theme.vars || theme).palette.text.primary,
                // border: '1px solid ',
                // borderColor: gray[200],
                backgroundColor: alpha(gray[50], 0.7),
                '.MuiInputBase-root &, .MuiDataGrid-treeDataGroupingCell &': {
                    backgroundColor: 'transparent'
                },
                '&:hover': {
                    backgroundColor: gray[100],
                    borderColor: gray[300]
                },
                '&:active': {
                    backgroundColor: gray[200]
                },
                variants: [
                    {
                        props: {
                            size: 'small'
                        },
                        style: {
                            width: '1.5rem',
                            minHeight: '1.5rem',
                            [`& .${svgIconClasses.root}`]: {fontSize: '1rem'}
                        }
                    },
                    {
                        props: {
                            size: 'medium'
                        },
                        style: {
                            width: '2.2rem',
                            minHeight: '2.2rem'
                        }
                    }
                ]
            })
        }
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: () => ({
                borderRadius: '10px',
                [`& .${toggleButtonGroupClasses.selected}`]: {
                    color: brand[500]
                }
            })
        }
    },
    MuiToggleButton: {
        styleOverrides: {
            root: () => ({
                padding: '12px 16px',
                textTransform: 'none',
                borderRadius: '10px',
                fontWeight: 500
            })
        }
    },
    MuiButtonGroup: {
        styleOverrides: {
            root: () => ({
                '& .MuiButton-root': {
                    marginTop: 0
                }
            })
        }
    },
    MuiFormControlLabel: {
        styleOverrides: {
            root: () => ({
                marginLeft: 0
            })
        }
    },
    MuiCheckbox: {
        defaultProps: {
            disableRipple: true,
            icon: (
                <CheckBoxOutlineBlankRoundedIcon sx={{color: 'hsla(210, 0%, 0%, 0.0)'}} />
            ),
            checkedIcon: <CheckRoundedIcon sx={{height: 14, width: 14}} />,
            indeterminateIcon: <RemoveRoundedIcon sx={{height: 14, width: 14}} />
        },
        styleOverrides: {
            root: () => ({
                margin: 10,
                height: 16,
                width: 16,
                borderRadius: 5,
                border: '1px solid ',
                borderColor: alpha(gray[300], 0.8),
                boxShadow: '0 0 0 1.5px hsla(210, 0%, 0%, 0.04) inset',
                backgroundColor: alpha(gray[100], 0.4),
                transition: 'border-color, background-color, 120ms ease-in',
                '&:hover': {
                    borderColor: brand[300]
                },
                '&.Mui-focusVisible': {
                    outline: `3px solid ${alpha(brand[500], 0.5)}`,
                    outlineOffset: '2px',
                    borderColor: brand[400]
                },
                '&.Mui-checked': {
                    color: 'white',
                    backgroundColor: brand[500],
                    borderColor: brand[500],
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: brand[600]
                    }
                }
            })
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                background: '#fff',
                '& .MuiIconButton-edgeEnd': {
                    marginRight: -8
                }
            }
        }
    }
    //       border: 'none',
    //     },
    //     input: {
    //       '&::placeholder': {
    //         opacity: 0.7,
    //         color: gray[500],
    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     input: {
    //       padding: 0,
    //     },
    //     root: ({ theme }) => ({
    //       padding: '8px 12px',
    //       color: (theme.vars || theme).palette.text.primary,
    //       borderRadius: (theme.vars || theme).shape.borderRadius,
    //       border: `1px solid ${(theme.vars || theme).palette.divider}`,
    //       backgroundColor: (theme.vars || theme).palette.background.default,
    //       transition: 'border 120ms ease-in',
    //       '&:hover': {
    //         borderColor: gray[400],
    //       },
    //       [`&.${outlinedInputClasses.focused}`]: {
    //         outline: `3px solid ${alpha(brand[500], 0.5)}`,
    //         borderColor: brand[400],
    //       },
    //       variants: [
    //         {
    //           props: {
    //             size: 'small',
    //           },
    //           style: {
    //             height: '2.25rem',
    //           },
    //         },
    //         {
    //           props: {
    //             size: 'medium',
    //           },
    //           style: {
    //             height: '2.5rem',
    //           },
    //         },
    //       ],
    //     }),
    //     notchedOutline: {
    //       border: 'none',
    //     },
    //   },
    // },
    // MuiInputAdornment: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       color: (theme.vars || theme).palette.grey[500]
    //     }),
    //   },
    // },
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       typography: theme.typography.caption,
    //       marginBottom: 8,
    //     }),
    //   },
    // },
};
