import React, {useContext} from 'react';
import {Box, ToggleButtonGroup, ToggleButton} from '@mui/material';
import {map, uniq} from 'lodash';
import moment from 'moment';
import {BarChart} from '@mui/x-charts';

import BaseChart from '../../../components/stats/BaseChart';

import {IncidentTypes, FirefighterRanks, colorForIncidentType} from '../../../data/utils';

import {UserContext} from '../../../contexts/User';
import {StationsContext} from '../../../contexts/Stations';

const Member = function(props) {
    const {incidents} = props;
    const [filter, setFilter] = React.useState('ALL');
    const {users} = useContext(UserContext);
    const {stations} = useContext(StationsContext);

    const attendance = users.reduce((result, user) => {
        const {uid, station, role, deactivated, ...rest} = user;

        if (!FirefighterRanks.includes(role)) {
            return result;
        }

        if (deactivated === true) {
            return result;
        }

        const userIncidents = incidents.reduce((result, incident) => {
            const {members = []} = incident;

            if (members.filter(Boolean).find(member => member.uid === uid)) {
                result.push(incident);
            }

            return result;
        }, []);

        if (filter === 'ALL' || filter === station) {
            if (!result[uid] && (userIncidents.length > 0 || user.deactivated !== true)) {
                result[uid] = {
                    uid,
                    ...rest,
                    incidents: userIncidents
                };
            }
        }

        return result;
    }, {});

    const incidentTypes = uniq(incidents.map(incident => incident.type));

    const dataset = map(attendance, member => {
        const {fullName, incidents} = member || {};
        const incidentTypeCounts = incidentTypes.reduce((result, type) => {
            result[type] = incidents.filter(incident => incident.type === type).length;

            return result;
        }, {});

        return {
            fullName,
            attendance: incidents.length,
            ...incidentTypeCounts
        };
    });

    const nightDataset = map(attendance, member => {
        let {fullName, incidents} = member || {};
        incidents = incidents.filter(incident => {
            const {date} = incident;
            const hour = moment(date).hour();

            return hour >= 23 || hour <= 7;
        });

        const incidentTypeCounts = incidentTypes.reduce((result, type) => {
            result[type] = incidents.filter(incident => incident.type === type).length;

            return result;
        }, {});

        return {
            fullName,
            attendance: incidents.length,
            ...incidentTypeCounts
        };
    });

    const fullNameTooltipValueFormatter = (fullName, context) => {
        const {location} = context;
        if (location === 'tooltip') {
            const {attendance} = dataset.find(item => item.fullName === fullName) || {};
            if (attendance) {
                return (
                    <>
                        <b>{fullName}</b>: {attendance} responses
                    </>
                );
            }
        }

        return fullName;
    };

    return (
        <Box>
            <ToggleButtonGroup
                fullWidth
                exclusive
                value={filter}
                variant="outlined"
                size="small"
                onChange={(e, value) => {
                    setFilter(value);
                }}
                sx={{mb: 2}}
            >
                <ToggleButton value="ALL">All</ToggleButton>

                {stations.map(station => {
                    const {uid, name} = station;
                    
                    return (
                        <ToggleButton key={uid} value={uid}>
                            Station {name}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>

            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                <BaseChart title="Responses" sx={{flex: 1, mr: 1}}>
                    <BarChart
                        margin={{left: 150, top: 20, right: 30, bottom: 40}}
                        dataset={[...dataset].sort((a, b) => b.attendance < a.attendance ? 1 : -1)}
                        yAxis={[{
                            scaleType: 'band',
                            dataKey: 'fullName',
                            valueFormatter: fullNameTooltipValueFormatter
                        }]}
                        slotProps={{legend: {hidden: true}}}
                        series={incidentTypes.map(type => ({
                            dataKey: type,
                            label: IncidentTypes[type],
                            stack: 'a',
                            color: colorForIncidentType(type)
                        }))}
                        xAxis={[{tickMinStep: 5}]}
                        layout="horizontal"
                        height={700}
                    />
                </BaseChart>

                <BaseChart title="Night Responses" subtitle="2300-0700" sx={{flex: 1}}>
                    <BarChart
                        margin={{left: 150, top: 20, right: 30, bottom: 40}}
                        dataset={[...nightDataset].sort((a, b) => b.attendance < a.attendance ? 1 : -1)}
                        yAxis={[{
                            scaleType: 'band',
                            dataKey: 'fullName',
                            valueFormatter: fullNameTooltipValueFormatter
                        }]}
                        slotProps={{legend: {hidden: true}}}
                        xAxis={[{tickMinStep: 2}]}
                        series={incidentTypes.map(type => ({
                            dataKey: type,
                            label: IncidentTypes[type],
                            stack: 'a',
                            color: colorForIncidentType(type)
                        }))}
                        layout="horizontal"
                        height={700}
                    />
                </BaseChart>
            </Box>
        </Box>
    );
};

export default Member;