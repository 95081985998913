import React from 'react';
import {DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid-pro';
import {Box, Stack, useTheme, useMediaQuery} from '@mui/material';

import StatefulDataGrid from './StatefulDataGrid';

function Toolbar() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <GridToolbarContainer>
            {!isSmall && (
                <Stack direction="row" spacing={1} sx={{flex: 1}}>
                    <GridToolbarColumnsButton />
                    <GridToolbarExport />
                </Stack>
            )}

            <GridToolbarQuickFilter variant="outlined" size="small" sx={{...isSmall && {flex: 1}}} />
        </GridToolbarContainer>
    );
}

const Searchable = props => {
    const {stateId, columns, rows, showTotals, disableToolbar, ...rest} = props;
    const Component = stateId ? StatefulDataGrid : DataGridPro;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const allRows = [...rows];
    if (showTotals) {
        const totalRow = rows.reduce((acc, row) => {
            Object.keys(row).forEach(key => {
                const {total: config} = columns.find(column => column.field === key) || {};

                if (config) {
                    if (config === 'sum') {
                        acc[key] = (acc[key] || 0) + row[key];
                    } else if (config === 'average') {
                        acc[key] = (acc[key] || 0) + row[key] / rows.length;
                    } else {
                        acc[key] = '';
                    }
                }
            });

            return acc;
        }, {});

        allRows.push({
            id: 'total',
            ...totalRow
        });
    }

    return (
        <Box sx={{width: '100%'}}>
            <Box style={{display: 'flex', flexDirection: 'column', minHeight: rows.length === 0 ? 250 : 'auto'}}>
                <Component
                    stateId={stateId}
                    disableColumnFilter
                    disableDensitySelector
                    slots={{toolbar: !disableToolbar && Toolbar}}
                    getRowClassName={row => (row.id === 'total' ? 'total-row' : '')}
                    rows={allRows}
                    columns={columns.map(column => {
                        return {
                            disableColumnMenu: isSmall,
                            ...column
                        };
                    })}
                    {...rest}
                />
            </Box>
        </Box>
    );
};

export default Searchable;