import React, {useMemo, useEffect, useState, useCallback, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Box, Typography, Divider} from '@mui/material';
import moment from 'moment';
import {GridActionsCellItem, useGridApiRef} from '@mui/x-data-grid-pro';
import {get, omit} from 'lodash';
import {useParams} from 'react-router-dom';
import {doc, collection, onSnapshot, addDoc, updateDoc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {useWindowSize} from '@uidotdev/usehooks';

import {UserContext} from '../../contexts/User';

import {uploadImage, verifyOfficer, processRawDoc, processRawDocs, getCollection, ensureJSDates} from '../../data/utils';

import SearchableDataGrid from '../../components/SearchableDataGrid';

import {db} from '../../firebase';

import ItemDialog from './ItemDialog';
import RefillDialog from './RefillDialog';

export const useItems = () => {
    const params = useParams();
    const [loadingItems, setLoadingItems] = useState(false);
    const [loadingParent, setLoadingParent] = useState(false);
    const [items, setItems] = useState([]);
    const [parent, setParent] = useState(null);
    const {users} = useContext(UserContext);

    const parentRefArgs = useMemo(() => {
        const {id: uid, ...otherParams} = params;
        let refArgs = [db];

        Object.entries(otherParams).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        refArgs.push('gear', uid);

        return refArgs;
    }, [db, params]);

    const refArgs = useMemo(() => {
        let refArgs = [...parentRefArgs];
        refArgs.push('items');

        return refArgs;
    }, [parentRefArgs]);

    useEffect(() => {
        setLoadingItems(true);

        const ref = collection(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            let rows = processRawDocs(snapshot);

            const {additionalFields = []} = parent || {};
            const apparatus = await getCollection(db, 'apparatus');

            setItems(rows.map(row => {
                const result = {...row};

                Object.keys(row).forEach(field => {
                    const value = row[field];
                    const {type = 'text'} = additionalFields.find(f => f.id === field) || {};

                    if (type === 'user') {
                        const user = users.find(user => user.uid === value);
                        result[field] = user;
                    } else if (type === 'apparatus') {
                        const apparatusItem = apparatus.find(item => item.uid === value);
                        result[field] = apparatusItem;
                    }
                });

                return result;
            }));

            setLoadingItems(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs, parent]);

    useEffect(() => {
        setLoadingParent(true);

        const ref = doc(...parentRefArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            let row = processRawDoc(snapshot);

            setParent(row);

            setLoadingParent(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, parentRefArgs]);

    const loading = loadingItems || loadingParent;

    return {
        items,
        parent,
        loading,
        refArgs,
        parentRefArgs
    };
};

export default function ItemsGrid(props) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [refillOpen, setRefillOpen] = useState(false);
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const params = useParams();
    const {enqueueSnackbar} = useSnackbar();
    const {uid: userUid} = currentUser;
    const apiRef = useGridApiRef();
    const {items: rows, parent, loading, refArgs} = useItems();
    const {width} = useWindowSize({debounceDelay: 100});

    const {name, additionalFields = []} = parent || {};
    const isCylinder = !!(name || '').match(/cylinder|bottle/i);

    const autosizeOptions = {
        columns: [
            'createdAt',
            ...additionalFields.map(field => field.id)
        ],
        includeOutliers: true,
        includeHeaders: true,
        expand: true
    };

    useEffect(() => {
        setTimeout(() => {
            apiRef.current.autosizeColumns(autosizeOptions);
        }, 100);
    }, [apiRef, rows, width]);

    const baseUrl = useMemo(() => {
        let parts = [];

        Object.entries(params).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        return `/${parts.join('/')}`;
    }, [params]);

    const onRowClick = useCallback((params, e) => {
        const {row} = params;
        const {uid} = row;
        const {metaKey} = e;

        const url = `${baseUrl}/${uid}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [baseUrl, navigate]);

    const onAddRefillRecord = useCallback(async data => {
        const {uid} = refillOpen;

        try {
            const args = [...refArgs, uid, 'records'];
            const ref = collection(...args);
            const {users = [], ...rest} = data;

            const record = ensureJSDates({
                ...rest,
                createdAt: new Date(),
                users: users.map(user => user.uid || user)
            });

            await updateDoc(doc(...[...refArgs, uid]), {
                lastRefill: new Date()
            });
            
            await addDoc(ref, record);
            return record;
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [enqueueSnackbar, refArgs, refillOpen]);

    const handleAdd = useCallback(() => {
        setOpen(true);
    }, []);

    const onAdd = useCallback(async data => {
        try {
            const {imageFile, ...rest} = data;
            
            const ref = collection(...refArgs);
            const record = ensureJSDates({
                ...omit(rest, ['uid', 'id']),
                createdAt: new Date(),
                user: userUid
            });

            const {id: uid} = await addDoc(ref, record);

            const path = [...refArgs.slice(1), uid].join('/');
            
            if (imageFile) {
                await Promise.all(imageFile.map(async file => {
                    return await uploadImage(path, file, true);
                }));
            }

            return {
                id: uid,
                uid,
                record
            };
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [enqueueSnackbar, refArgs, userUid]);

    const handleRefill = useCallback(row => {
        setRefillOpen(row);
    }, []);

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Created',
            sortable: true,
            width: 105,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '-';
            }
        },
        ...additionalFields.map(field => {
            const {id, label: headerName, type} = field;

            return {
                field: id,
                headerName,
                sortable: true,
                // type: type === 'date' ? 'date' : 'string',
                // valueGetter: rawValue => {
                //     let value = rawValue;

                //     if (type === 'apparatus') {
                //         value = rawValue ? rawValue.tag : '-';
                //     } else if (type === 'user') {
                //         value = rawValue ? rawValue.fullName : '-';
                //     }

                //     return value;
                // },
                valueFormatter: value => {
                    if (type === 'date') {
                        return value ? moment(value).format(dateFormat) : '-';
                    }

                    return value ? value : '-';
                }
                // renderCell: params => {
                //     const {value} = params;

                //     return renderMatchCell({value});
                // }
            };
        })
    ];

    if (isCylinder) {
        columns.push({
            type: 'date',
            field: 'lastRefill',
            headerName: 'Last Refill',
            minWidth: 105,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '-';
            }
        });

        columns.push({
            type: 'actions',
            field: 'actions',
            width: 50,
            getActions: params => {
                const {row} = params;
                const {uid} = row || {};

                return [
                    <GridActionsCellItem key={`action-refill-${uid}}`} icon={<AutorenewIcon />} label="Refill" onClick={() => handleRefill(row)} />
                ];
            }
        });
    }

    const stateId = refArgs.slice(1).join('-');

    return (
        <Box {...props}>
            {isCylinder && (
                <RefillDialog onSubmit={onAddRefillRecord} open={!!refillOpen} handleClose={() => setRefillOpen(false)} />
            )}

            <Box sx={{display: 'flex', alignItems: 'flex-end', mb: 1, mt: 1}}>
                <Typography variant="h6" sx={{flex: 1}}>Items</Typography>

                {isOfficer && (
                    <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                        <ItemDialog parent={parent} onSubmit={onAdd} open={open} handleClose={() => setOpen(false)} />
                        <Box sx={{flexGrow: 1}} />
                        <Button startIcon={<AddIcon />} disabled={loading} size="small" variant="outlined" onClick={handleAdd}>
                            Add New Item
                        </Button>
                    </Box>
                )}
            </Box>

            <Divider sx={{mb: 1}} />

            <SearchableDataGrid
                apiRef={apiRef}
                stateId={stateId}
                initialState={{
                    sorting: {
                        sortModel: [
                            {field: 'createdAt', sort: 'asc'}
                        ]
                    }
                }}
                autosizeOptions={autosizeOptions}
                autosizeOnMount
                localeText={{noRowsLabel: 'No items'}}
                loading={loading}
                rows={rows.map(row => {
                    const result = {...row};

                    for (const field of additionalFields) {
                        const {id, type} = field;
                        let value = row[id];

                        if (type === 'user') {
                            value = value ? value.fullName : '-';
                        } else if (type === 'apparatus') {
                            value = value ? value.tag : '-';
                        }

                        result[id] = value;
                    }

                    return result;
                })}
                columns={columns.map(column => ({...column, disableColumnMenu: true}))}
                pageSizeOptions={[]}
                disableRowSelectionOnClick
                onRowClick={onRowClick}
                slotProps={{
                    loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'skeleton'
                    },
                    columnsManagement: {
                        getTogglableColumns: columns => {
                            return columns.filter(column => column.field !== 'actions').map(column => column.field);
                        }
                    }
                }}
                sx={{
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    }
                }}
            />
        </Box>
    );
};