import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControl, FormHelperText, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {capitalize} from 'lodash';

export default function ControlledToggleButtonField(props) {
    const {name, rule: rawRules, required, label, variant = 'outlined', options, fullWidth, margin, sx, button, ...rest} = props;
    const rules = {...rawRules};
    if (required && !rules.required) {
        rules.required = `${label || capitalize(name)} is required`;
    }

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <FormControl fullWidth={fullWidth} margin={margin} sx={sx}>
                        <ToggleButtonGroup
                            value={value}
                            variant={variant}
                            onChange={(e, values) => {
                                if (values && values.length) {
                                    onChange(values);
                                } else {
                                    onChange([]);
                                }
                            }}
                            sx={{display: 'flex', background: 'transparent', boxShadow: 'none'}}
                            {...rest}
                            {...restField}
                        >
                            {options.map(option => (
                                <ToggleButton
                                    {...button}
                                    key={option.value}
                                    sx={{...fullWidth && {flex: 1}}}
                                    value={option.value}
                                    {...option.color && {color: option.color}}
                                >
                                    {option.label}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                        {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormControl>
                );
            }}
        />
    );
};
