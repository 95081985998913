import React, {useEffect, useCallback, useState} from 'react';
import {Box, Button} from '@mui/material';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import {onSnapshot, collection} from 'firebase/firestore';
import moment from 'moment';
import {httpsCallable} from 'firebase/functions';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import {db, functions} from '../firebase.js';

import {processRawDocs} from '../data/utils';

const localizer = momentLocalizer(moment);

export default function User() {
    const [events, setEvents] = useState([]);
    const importEventsFromIAR = httpsCallable(functions, 'importEventsFromIAR');

    const fetch = useCallback(() => {
        const ref = collection(db, 'events');
        const unsubscribe = onSnapshot(ref, snapshot => {
            const events = processRawDocs(snapshot);
            setEvents(events);
        });

        return unsubscribe;
    }, [db]);

    const handleImport = async () => {
        try {
            await importEventsFromIAR();
        } catch(e) {
            console.error(e);
        }
    };

    useEffect(() => {
        const unsubscribe = fetch();
        
        return () => {
            unsubscribe();
        };
    }, [fetch]);

    return (
        <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <Button onClick={handleImport}>Import Events</Button>

            <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                style={{
                    flex: 1,
                    minHeight: 430
                }}
                events={events}
            />
        </Box>
    );
}