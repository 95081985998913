import React from 'react';

export const joinWithAnd = function(arr) {
//     const formatter = new Intl.ListFormat('en', {style: 'long', type: 'conjunction'});
//     return formatter.format(arr);

    if (arr.length === 0) {
        return '';
    }

    if (arr.length === 1) {
        return arr[0];
    }

    if (arr.length === 2) {
        return (
            <>
                {arr[0]} & {arr[1]}
            </>
        );
    }
    
    return (
        <>
            {arr.slice(0, -1).map((item, index) => (
                <span key={`item-${index}`}>{item}{index < arr.length - 2 ? ', ' : ''}</span>
            ))} & {arr[arr.length - 1]}
        </>
    );
}

export const formatDuration = function(ms) {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    const days = Math.floor(ms / (1000 * 60 * 60 * 24));

    const parts = [];
    if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);
    if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    if (seconds > 0) parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);

    return parts.length > 0 ? parts.join(', ') : '0 seconds';
};