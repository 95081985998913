import React, {useEffect, useState} from 'react';
import {Avatar, Tooltip} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ref, getDownloadURL} from 'firebase/storage';

import {storage} from '../firebase';

const stringToColor = string => {
    if (!string) {
        return;
    }

    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
};

// theme.palette

export default function UserAvatar(props) {
    const {user, sx, ...rest} = props;
    
    const theme = useTheme();
    const {uid, image, name, firstName = '', lastName = '', fullName = '', isStation} = user || {};
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url)
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url)
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [image]);

    if (!uid) {
        return '-';
    }
    
    let displayText = `${firstName[0]}${lastName[0]}`;
    if (!firstName || !lastName) {
        displayText = '';
    }

    let bgcolor = stringToColor(fullName);
    if (isStation) {
        displayText = name;
        bgcolor = theme.palette.primary.main;
    }

    return (
        <Tooltip title={fullName}>
            <Avatar
                src={imageUrl}
                alt={fullName}
                sx={{
                    color: '#fff !important',
                    bgcolor,
                    ...sx
                }}
                {...rest}
            >
                {displayText}
            </Avatar>
        </Tooltip>
    );
};