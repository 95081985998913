import React from 'react';
import {Box} from '@mui/material';
import {sortBy, reduce, groupBy} from 'lodash';
import moment from 'moment';
import {BarChart, PieChart, LineChart} from '@mui/x-charts';

import BaseChart from '../../../components/stats/BaseChart';

import {IncidentTypes, colorForIncidentType} from '../../../data/utils';

const Type = function({incidents}) {
    const incidentsByType = reduce(incidents.reduce((result, doc) => {
        const {type} = doc;

        if (!result[type]) {
            result[type] = {
                value: 0,
                label: IncidentTypes[type],
                type,
                incidents: []
            };
        }

        result[type].value++;
        result[type].incidents.push(doc);

        return result;
    }, {}), (result, value, key) => {
        const {type, incidents} = value || {};
        const groupedByMonth = groupBy(incidents, incident => {
            const {date} = incident;
            return moment(date).format('YYYY-MM');
        });

        result.push({
            id: key,
            ...value,
            type,
            groupedByMonth,
            color: colorForIncidentType(type)
        });

        return result;
    }, []).sort((a, b) => {
        return b.value < a.value ? 1 : -1;
    });

    const incidentsByMonth = sortBy(reduce(incidents.reduce((result, doc) => {
        const {date} = doc;
        const month = moment(date).format('YYYY-MM');

        if (!result[month]) {
            result[month] = {
                date,
                value: date,
                label: moment(date).format('MMMM YYYY'),
                incidents: []
            };
        }

        result[month].incidents.push(doc);

        return result;
    }, {}), (result, value, key) => {
        const {incidents} = value || {};
        const groupedByType = groupBy(incidents, 'type');

        result.push({
            id: key,
            ...value,
            groupedByType
        });

        return result;
    }, []), 'id');

    console.log(incidentsByMonth)

    const handleTypeClick = () => {
        // const {id} = item;
        
        // console.log(id);
    };

    return (
        <Box sx={{mb: 2}}>
            <BaseChart title="Incidents by Type">
                <PieChart
                    series={[{
                        faded: {innerRadius: 30, additionalRadius: -30, color: 'gray'},
                        data: incidentsByType,
                        highlightScope: {fade: 'global', highlight: 'item'},
                        label: 'Number of Incidents',
                        innerRadius: 20,
                        paddingAngle: 2,
                        cornerRadius: 2
                    }]}
                    height={300}
                    margin={{top: 10, right: 200, bottom: 10, left: 10}}
                    onClick={handleTypeClick}slotProps={{
                        legend: {
                            labelStyle: {
                                fontSize: 12
                            },
                            itemMarkHeight: 10,
                            itemMarkWidth: 10
                        }
                    }}
                />
            </BaseChart>

            <BaseChart title="Incidents by Month" sx={{mt: 2}}>
                <BarChart
                    xAxis={[{
                        scaleType: 'band',
                        data: incidentsByMonth.map(({label}) => label)
                    }]}
                    slotProps={{legend: {hidden: true}}}
                    series={incidentsByType.map(byType => {
                        const {type, label, groupedByMonth} = byType;
                        const data = incidentsByMonth.map(byMonth => {
                            const {id} = byMonth;

                            return groupedByMonth[id] ? groupedByMonth[id].length : 0;
                        });

                        return {
                            stack: 'A',
                            label,
                            data,
                            color: colorForIncidentType(type)
                        }
                    })}
                    height={300}
                />
            </BaseChart>

            <BaseChart title="Incidents by Type over Time" sx={{mt: 2}}>
                <LineChart
                    slotProps={{legend: {hidden: true}}}
                    xAxis={[{
                        scaleType: 'point',
                        data: incidentsByMonth.map(({label}) => label)
                    }]}
                    series={incidentsByType.map(byType => {
                        const {type, label, groupedByMonth} = byType;
                        const data = incidentsByMonth.map(byMonth => {
                            const {id} = byMonth;

                            const [year, month] = id.split('-');
                            let total = 0;
                            
                            for (let i = 1; i <= parseInt(month); i++) {
                                const key = i < 10 ? `0${i}` : i;
                                const grouped = groupedByMonth[`${year}-${key}`] || [];
                                total += grouped.length;
                            }

                            return total;
                        });

                        return {
                            stack: 'total',
                            color: colorForIncidentType(type),
                            label,
                            data,
                            area: true,
                            showMark: false,
                            curve: 'linear'
                        }
                    })}
                    height={450}
                    sx={{
                        '.MuiLineElement-root': {
                            display: 'none'
                        }
                    }}
                />
            </BaseChart>
        </Box>
    );
};

export default Type;