import React, {useState, useEffect, useCallback} from 'react';
import {Grid2 as Grid, Card, CardContent, Typography, Box, IconButton, Tab, Button, TextField as UncontrolledTextField, Autocomplete} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useNavigate} from 'react-router-dom';
import {useForm, FormProvider} from 'react-hook-form';
import {doc, getDoc, deleteDoc, updateDoc} from 'firebase/firestore';
import {useParams} from 'react-router-dom';
import {isNil, uniq, get, sortBy, isString} from 'lodash';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import OpenAI from 'openai';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {db} from '../firebase';

import useDocumentTitle from '../hooks/useDocumentTitle';

import {TrainingTypes, TrainingDisciplines, populateUsers} from '../data/utils';
import {Standards, getStandardForKey} from '../data/standards';

import TextField from '../form/TextField.js';
import SelectField from '../form/SelectField.js';
import UserAutocompleteField from '../form/UserAutocompleteField';
import CollectionAutocompleteField from '../form/CollectionAutocompleteField';

import SmallChip from '../components/SmallChip';

import MemberHours from '../components/members/Hours';

import {StandardSubsection} from '../pages/NFPA';

const OpenAIKey = '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';

function extractAndParseJSON(openAIResponse) {
    // Regular expression to find content between ```json ... ```
    const jsonRegex = /```json([\s\S]*?)```/g;
    let match = jsonRegex.exec(openAIResponse);
    
    // Array to hold all JSON objects found
    const jsonObjects = [];

    while (match !== null) {
        let jsonString = match[1].trim(); // Get the matched content and trim any whitespace

        // Remove comments (both single-line and multi-line)
        jsonString = jsonString
            .replace(/\/\/.*$/gm, '')          // Remove single-line comments
            .replace(/\/\*[\s\S]*?\*\//g, ''); // Remove multi-line comments

        try {
            const parsedObject = JSON.parse(jsonString);
            jsonObjects.push(parsedObject); // Add parsed JSON object to the array
        } catch (error) {
            console.error('Failed to parse JSON:', error);
        }
        match = jsonRegex.exec(openAIResponse); // Find the next match
    }

    return jsonObjects;
}

export default function Training() {
    const {id: uid} = useParams();
    const [loading, setLoading] = useState(!!uid);
    const [deleting, setDeleting] = useState(false);
    const [guessingJprs, setGuessingJprs] = useState(false);
    const [searchJpr, setSearchJpr] = useState('');
    const [activeTab, setActiveTab] = useState('details');
    const [foundJprs, setFoundJprs] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const navigate = useNavigate();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const openai = new OpenAI({
        apiKey: OpenAIKey,
        dangerouslyAllowBrowser: true
    });

    const methods = useForm({
        defaultValues: {
            type: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, watch, setValue, formState, reset} = methods;
    const {isValid, dirtyFields} = formState;
    const dirtyKeys = Object.keys(dirtyFields);

    const jprs = watch('jprs', []);
    const title = watch('raw.type');
    const hours = watch('hours');
    const description = watch('raw.description');
    const members = watch('members');
    const hoursOverrides = watch('hoursOverrides', {});
    const sortedMembers = sortBy(members, ['station', 'lastName']);

    useDocumentTitle(title);

    const handleHourChange = useCallback((e, member) => {
        let newValue = parseFloat(e.target.value);
        const existing = hoursOverrides || {};

        if (isNaN(newValue)) {
            delete existing[member.uid];

            if (Object.keys(existing).length) {
                setValue('hoursOverrides', existing, {shouldDirty: true});
            } else {
                setValue('hoursOverrides', null, {shouldDirty: true});
            }
        } else {
            if (isNil(existing[member.uid])) {
                if (newValue === 0) {
                    newValue = parseFloat(hours) - 0.5;
                } else {
                    newValue += parseFloat(hours);
                }
            }

            setValue(`hoursOverrides.${member.uid}`, newValue, {shouldDirty: true});
        }
    }, [setValue, hours, hoursOverrides]);

    const onSubmit = useCallback(async data => {
        const dirtyKeys = Object.keys(dirtyFields);
        if (!dirtyKeys.length) {
            return;
        }

        setLoading(true);

        const newData = dirtyKeys.reduce((acc, key) => {
            const value = data[key];
            
            if (Array.isArray(value)) {
                acc[key] = value.map(v => isString(v) ? v : v.uid);
            } else {
                acc[key] = value;
            }
            
            return acc;
        }, {});

        try {
            const ref = doc(db, 'training', uid);
            await updateDoc(ref, {
                updated: new Date(),
                ...newData
            });

            reset(data);

            enqueueSnackbar('Changed saved', {variant: 'success'});

            navigate('/training');
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dirtyFields, db, uid, enqueueSnackbar, reset, navigate]);

    const handleGuessJprs = useCallback(async() => {
        setGuessingJprs(true);
        setFoundJprs([]);

        const assistant = await openai.beta.assistants.retrieve('asst_wzShAMqLi5grCaKng8XCPbB0');
        const thread = await openai.beta.threads.create();
        await openai.beta.threads.messages.create(
            thread.id,
            {
                role: 'user',
                content: [{
                    type: 'text',
                    text: description
                }]
            }
        );

        const run = await openai.beta.threads.runs.createAndPoll(
            thread.id,
            {assistant_id: assistant.id}
        );

        if (run.status === 'completed') {
            const messages = await openai.beta.threads.messages.list(
                run.thread_id
            );
            
            const assistantMessages = messages.data.filter(message => message.role === 'assistant');
            for (const message of assistantMessages.reverse()) {
                try {
                    const [found] = extractAndParseJSON(message.content[0].text.value);
                    if (found.length) {
                        const filtered = found.filter(jpr => {
                            return Standards.some(standard => {
                                return standard.value === jpr;
                            });
                        });

                        enqueueSnackbar(`Found ${filtered.length} JPRs`, {variant: 'success'});
                        setFoundJprs(filtered);

                        setValue('jprs', uniq([...jprs, ...filtered]), {shouldDirty: true});
                        break;
                    }
                } catch(e) {
                    console.warn('Unable to parse message', message);

                    enqueueSnackbar('Unable to find any JPRs. Please try again with an updated summary.', {variant: 'error'});
                }
            }
        } else {
            enqueueSnackbar('There was a problem guesing JPRs. Please try again in a few minutes.', {variant: 'error'});
            console.warn(run.last_error, run);
        }

        setGuessingJprs(false);
    }, [description, jprs, openai, setValue, enqueueSnackbar]);

    const handleDelete = useCallback(async() => {
        const onDelete = async() => {
            try {
                setDeleting(true);
    
                const ref = doc(db, 'incidents', uid);
                await deleteDoc(ref);

                navigate('/training');
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        enqueueSnackbar('Are you sure you want to delete this training?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [enqueueSnackbar, db, uid, navigate, closeSnackbar]);

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'training', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate(-1);
                    }

                    const {date: rawDate, ...rest} = raw.data();
                    const date = moment(rawDate.toDate());
                    const doc = await populateUsers(db, rest);
                    
                    reset({
                        ...doc,
                        id: uid,
                        uid,
                        date
                    });

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, reset, uid, navigate]);

    const handleDeleteJpr = useCallback(jprKey => {
        const newJprs = jprs.filter(j => j !== jprKey);
        setValue('jprs', newJprs, {shouldDirty: true});
    }, [jprs, setValue]);

    const handleAddJpr = useCallback(jprKey => {
        const newJprs = uniq([...jprs, jprKey]);
        setValue('jprs', newJprs, {shouldDirty: true});
    }, [jprs, setValue]);

    const options = Standards.filter(standard => !(jprs || []).includes(standard.value)).map(option => {
        const {standard} = option || {};
        const {title, edition} = standard || {};

        return {
            groupBy: `${title}${edition ? ` (${edition})` : ''}`,
            ...option
        };
    });

    const matchOptions = {insideWords: true,  requireMatchAll: true};

    return (
        <FormProvider {...methods}>
            <Typography variant="h5" gutterBottom>Edit Training</Typography>

            <TabContext value={activeTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList onChange={(e, tab) => setActiveTab(tab)}>
                        <Tab label="Details" value="details" />
                        <Tab label="JPRs" value="jprs" />
                        <Tab label="Attendance" value="attendance" />
                    </TabList>
                </Box>

                <TabPanel value="details" sx={{px: 0}}>
                    <Grid container spacing={2}>
                        <Grid size={12}>
                            <TextField
                                fullWidth
                                label="Title"
                                name="raw.type"
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={12} container spacing={2}>
                            <Grid size={{xs: 6, lg: 3}}>
                                <SelectField
                                    fullWidth
                                    name="type"
                                    label="Type"
                                    options={Object.keys(TrainingTypes).map(value => ({
                                        value,
                                        label: TrainingTypes[value]
                                    }))}
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid size={{xs: 6, lg: 3}}>
                                <SelectField
                                    fullWidth
                                    name="discipline"
                                    label="Discipline"
                                    options={Object.keys(TrainingDisciplines).map(value => ({
                                        value,
                                        label: TrainingDisciplines[value]
                                    }))}
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid size={{sm: 12, lg: 6}}>
                                <CollectionAutocompleteField
                                    fullWidth
                                    label="Keywords"
                                    name="types"
                                    collection="trainingTypes"
                                    multiple
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>

                        <Grid size={12}>
                            <TextField
                                fullWidth
                                multiline
                                label="Description"
                                name="raw.description"
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={12}>
                            <UserAutocompleteField
                                fullWidth
                                label="Instructors"
                                name="instructors"
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value="jprs" sx={{px: 0}}>
                    <Box sx={{display: 'flex', mb: 2, flexDirection: 'row'}}>
                        <Autocomplete
                            sx={{flex: 1}}
                            options={options}
                            disableCloseOnSelect
                            getOptionLabel={option => {
                                if (!option) {
                                    return '';
                                }

                                const {key, title} = option || {};
                                return `${key}: ${title}`;
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option.value === value.value;
                            }}
                            filterOptions={(options, params) => {
                                const {inputValue} = params;
                                if (!inputValue) {
                                    return options;
                                }
                                
                                const keys = ['label', 'key', 'title', 'text'];
                                return options.filter(option => {
                                    return keys.some(key => {
                                        const value = option[key] || '';
                                        return match(value, inputValue, matchOptions).length;
                                    });
                                });
                            }}
                            groupBy={option => {
                                const {groupBy} = option || {};
                                return groupBy;
                            }}
                            clearOnBlur={false}
                            value={''}
                            inputValue={searchJpr}
                            onChange={(e, value) => value && handleAddJpr(value.value)}
                            onInputChange={(event, newInputValue) => {
                                const {type} = event || {};

                                if (type === 'click') {
                                    return;
                                }

                                setSearchJpr(newInputValue);
                            }}
                            renderInput={params => (
                                <UncontrolledTextField
                                    {...params}
                                    label="Search JPRs"
                                    placeholder="4.1.1, hose testing, scba, etc."
                                />
                            )}
                            renderGroup={params => {
                                const {key, group, children} = params;

                                return (
                                    <>
                                        <Box key={key} sx={{fontWeight: 'bold', backgroundColor: '#f9f9fa', p: 1}}>
                                            {group}
                                        </Box>
                                        {children}
                                    </>
                                );
                            }}
                            renderOption={(props, option, {inputValue}) => {
                                const {key: optionKey, ...optionProps} = props;

                                const keys = ['key', 'title', 'text'];
                                const {key, title, text} = keys.reduce((result, key) => {
                                    const value = option[key] || '';
                                    const matches = match(value, inputValue, matchOptions);
                                    const parts = parse(value, matches);

                                    result[key] = parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ));
                                    
                                    return result;
                                }, {});

                                return (
                                    <Box component="li" key={optionKey} {...optionProps} sx={{borderTop: 1, borderColor: '#eee'}}>
                                        <Box>
                                            <Typography sx={{fontWeight: 'bold'}}>{key}. {title}</Typography> {text}
                                        </Box>
                                    </Box>
                                );
                            }}
                        />

                        <LoadingButton onClick={handleGuessJprs} loading={guessingJprs} size="large" variant="contained" sx={{ml: 2}}>Guess JPRs</LoadingButton>
                    </Box>

                    {jprs && jprs.map((jprKey, index) => {
                        const {standard, key, title, text: rawText, data = {}, parent} = getStandardForKey(jprKey) || {};
                        const {title: standardTitle, edition} = standard || {};
                        const {title: parentTitle} = parent || {};
                        const subsections = Object.keys(data).filter(key => parseInt(key) > 0);

                        const lines = (rawText || '').split('\n');
                        let [text, ...otherLines] = lines;
                        if (text && otherLines.length > 0 && text.startsWith('(')) {
                            otherLines = [text, ...otherLines].filter(Boolean);
                            text = '';
                        }

                        const found = foundJprs.find(jpr => jpr === jprKey);
                        const canExpand = subsections.length > 0;
                        const isExpanded = get(expanded, jprKey, false);

                        return (
                            <Card variant="outlined" {...found && {severity: 'warning'}} key={`jpr-${index}`} sx={{mb: 1}}>
                                <CardContent sx={{position: 'relative', '&:last-child': {pb: 1}}}>
                                    {standardTitle && <Typography gutterBottom sx={{color: 'text.secondary'}}>{standardTitle}{edition && ` (${edition})`}</Typography>}
                                    {parentTitle && <Box sx={{fontWeight: 'bold', mb: 1}}>{parentTitle}</Box>}
                                    <span style={{fontWeight: 'bold'}}>{key}. {title && `${title}${text ? '.' : ''} `}</span>
                                    {text && <span>{text}</span>}

                                    {otherLines.length > 0 && (
                                        <Box sx={{ml: 1, mt: 0.5}}>
                                            {otherLines.map((line, i) => <Box key={`${jprKey}.line.${i}`}>{line}</Box>)}
                                        </Box>
                                    )}

                                    {canExpand && (
                                        <Box sx={{mt: 1}}>
                                            <SmallChip icon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} label={isExpanded ? 'Show Less' : 'Show More'} onClick={() => setExpanded({...expanded, [jprKey]: !isExpanded})} />
                                        </Box>
                                    )}

                                    {isExpanded && subsections.map((subsection, index) => <StandardSubsection key={`${jprKey}-${index}`} parent={data} chapter={key} subsection={subsection} />)}

                                    <IconButton sx={{position: 'absolute', top: 10, right: 10}} onClick={() => handleDeleteJpr(jprKey)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </CardContent>
                            </Card>
                        );
                    })}
                </TabPanel>

                <TabPanel value="attendance" sx={{px: 0}}>
                    <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                        <TextField
                            sx={{flex: 1}}
                            label="Hours"
                            name="hours"
                            disabled={loading}
                            type="number"
                            inputProps={{min: 0, step: 0.5}}
                        />
                    </Box>

                    <MemberHours
                        members={sortedMembers}
                        defaultSalary={hours}
                        salaryOverrides={hoursOverrides}
                        handleHourChange={handleHourChange}
                    />

                    <Box sx={{display: 'flex', flexDirection: 'row', mt: 3}}>
                        <UserAutocompleteField
                            sx={{flex: 1}}
                            label="Members"
                            name="members"
                            disabled={loading}
                        />
                    </Box>
                </TabPanel>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', mb: 2}}>
                    <LoadingButton onClick={handleDelete} sx={{mr: 1}} loading={deleting} component="label">
                        Delete Training
                    </LoadingButton>
                    
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        disabled={loading || !isValid || dirtyKeys.length === 0}
                        loading={loading}
                    >
                        Save Training
                    </LoadingButton>
                </Box>
            </TabContext>
        </FormProvider>
    );
}