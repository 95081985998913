import React, {useContext, useState} from 'react';
import {Box, Typography, Stack} from '@mui/material';
import MuiDrawer, {drawerClasses} from '@mui/material/Drawer';
import {alpha, styled} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';

import {UserContext} from '../contexts/User';
import {SettingsContext} from '../contexts/Settings';

import {Ranks} from '../data/utils';

import HeaderBreadcrumbs from './HeaderBreadcrumbs';
import UserAvatar from '../components/UserAvatar';

import Navbar from './navigation/Navbar';
import MenuContent from './navigation/MenuContent';
import UserMenu from './navigation/UserMenu';

import FirebaseImage from './FirebaseImage';

const Drawer = styled(MuiDrawer)({
    minWidth: 240,
    flex: 1,
    boxSizing: 'border-box',
    mt: 10,
    [`& .${drawerClasses.paper}`]: {
        width: 240,
        boxSizing: 'border-box'
    }
});

const AppContainer = props => {
    const {children} = props;
    const {currentUser} = useContext(UserContext);
    const {image} = useContext(SettingsContext);
    const {pathname} = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    if (!currentUser) {
        return children;
    }

    const {email, fullName, role, isStation} = currentUser || {};
    const isFullscreen = /matrix|calendar/.test(pathname);

    return (
        <Box
            sx={theme => ({
                display: 'flex',
                height: '100vh',
                '& .highlighted': {
                    backgroundColor: 'rgb(255, 242, 199, 0.8)',
                    fontWeight: 700
                },
                '& span.highlighted': {
                    position: 'relative'
                },
                '& span.highlighted:after': {
                    content: '""',
                    backgroundColor: 'rgb(255, 242, 199, 0.8)',
                    position: 'absolute',
                    top: -1,
                    bottom: -1,
                    right: -2,
                    left: -2,
                    zIndex: -1,
                    borderRadius: 1
                },
                backgroundColor: theme.vars
                    ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
                    : alpha(theme.palette.background.default, 1)
            })}
        >
            <Drawer
                variant="permanent"
                sx={{
                    display: {xs: 'none', md: 'block'},
                    [`& .${drawerClasses.paper}`]: {
                        backgroundColor: 'background.paper'
                    }
                }}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                ModalProps={{
                    keepMounted: true
                }}
            >
                <Box sx={{mt: 2, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    {image ? (
                        <FirebaseImage image={image} sx={{width: '60%'}} />
                    ) : (
                        <img src="/logo.png" alt="Ember Tracking" style={{width: '60%'}} />
                    )}
                </Box>

                <MenuContent />

                <Stack
                    direction="row"
                    sx={{
                        width: '100%',
                        p: 2,
                        gap: 1,
                        alignItems: 'center',
                        borderTop: '1px solid',
                        borderColor: 'divider'
                    }}
                >
                    <UserAvatar user={currentUser} sx={{width: 36, height: 36}} />
                    <Box sx={{flex: 1}}>
                        <Typography variant="body2" sx={{fontWeight: 500, lineHeight: '16px', overflowWrap: 'break-word', wordWrap: 'break-word', hyphens: 'auto'}}>
                            {fullName || email}
                        </Typography>
                        {!isStation && (
                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                {Ranks[role]}
                            </Typography>
                        )}
                    </Box>
                    <UserMenu />
                </Stack>
            </Drawer>

            <Navbar />

            {isFullscreen ? (
                <Box sx={{minWidth: 0, display: 'flex', flexDirection: 'column', p: 2, mt: {xs: 9, sm: 9, md: 0, width: '100%'}}}>
                    <HeaderBreadcrumbs />

                    {children}
                </Box>
            ) : (
                <Box sx={{minWidth: 0, display: 'flex', flexDirection: 'column', p: 2, mt: {xs: 9, sm: 9, md: 0}, width: '100%'}}>
                    <HeaderBreadcrumbs />

                    <Box sx={{pb: 2}}>
                        {children}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default AppContainer;
